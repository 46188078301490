<template>
  <div class="fill-height" style="overflow: hidden">
    <v-snackbar v-model="notify" class="text-center" timeout="1500" :color="notifyColor">
      <div class="text-right">
        {{ notifyText }}
      </div>
    </v-snackbar>
    <div class="stepperContext fill-height">
       <v-fade-transition mode="out-in">
        <div :key="1" v-if="state == 'introSlider'">
          <div class="d-flex justify-space-between pt-5">
            <div>
              <v-fade-transition>
                <v-icon v-if="e1 != 1" @click="e1--" style="cursor:pointer;">
                  mdi-arrow-left
                </v-icon>
              </v-fade-transition>
            </div>
            <div>
              <v-btn @click="endIntroSlider" text color="grey">
                رد کردن
              </v-btn>
            </div>
          </div>
          <v-stepper class="" v-model="e1" elevation="0">
            <v-stepper-items class="fill-height">
              <v-stepper-content step="1" class="pb-10 ">
                <div class="fill-height pb-5">
                  <div class="introSlide">
                    <img src="../../assets/static_presession/onboarding-step1.2a2c3729de83a7efe9a9.png" alt="">
                    <div class="sliderContent">
                      <div class="titleSlider">
                        برای ساختن حال خوب همراهت هستیم
                      </div>
                      <div class="contentSlider">
                        سیمیاروم به کمک یه تیم تخصصی تا حالا همراه ایرانیان مهاجر از ۷۵ کشور بوده
                      </div>
                      <div class="buttonSlier " @click="e1++">
                        بعدی
                      </div>
                    </div>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content step="2" class="pb-10 ">
                <div class="fill-height pb-5">
                  <div class="introSlide">
                    <img src="../../assets/static_presession/onboarding-step2.5ea9f768329eb5aa0dc0.png" alt="">
                    <div class="sliderContent">
                      <div class="titleSlider">
                        زمان زیادی لازم نیست
                      </div>
                      <div class="contentSlider">
                        برای اینکه بهترین مسیر رو بهت پیشنهاد بدیم، لازمه نیازها و شرایطت رو بدونیم.
                      </div>
                      <div class="buttonSlier " @click="e1++">
                        بعدی
                      </div>
                    </div>
                  </div>
                </div>
              </v-stepper-content>
              <v-stepper-content step="3" class="pb-10 ">
                <div class="fill-height pb-5">
                  <div class="introSlide">
                    <img src="../../assets/static_presession/onboarding-step3.3162f00cf13370e4130c.png" alt="">
                    <div class="sliderContent">
                      <div class="titleSlider">
                        این انتخاب شماست
                      </div>
                      <div class="contentSlider">
                        درسته تو در نهایت خودت تراپیستت رو انتخاب می‌کنی؛ ولی ما بهترین گزینه‌ها رو بهت معرفی می‌کنیم.
                      </div>
                      <div class="buttonSlier " @click="endIntroSlider">
                        بعدی
                      </div>
                    </div>
                  </div>
                </div>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </div>
        <div :key="2" v-else-if="state == 'question'">
          <div class="headerContext d-flex pt-8">
            <div class="d-flex flex-row-reverse">
              <div class="questionCounter">
                {{ questionStepper }}
              </div>
              /
              <div class="totalQuestionCount">
                {{ questions.length }}
              </div>
            </div>
            <div>
              <img src="../../assets/logo/Logo.svg" alt="">
            </div>
            <div>
              <div>
                <v-fade-transition>
                  <v-icon @click="questionStepper--" v-if="questionStepper != 1" style="cursor:pointer;">
                    mdi-arrow-left
                  </v-icon>
                </v-fade-transition>
              </div>
            </div>
          </div>
          <div class="progressArea mt-5">
            <v-progress-linear color="primary" :value="progress" background-color="rgb(216 221 221/1)" height="8"
                               rounded></v-progress-linear>
          </div>
          <div class="mt-15">
            <div>
              <v-stepper elevation="0" v-model="questionStepper">
                <v-stepper-content :step="(i +1 )" v-for="(item , i) in questions" :key="i">
                  <div class="questionSlider">
                    <div class="titleQuestion">
                      {{ item.question }}
                    </div>
                    <v-form :ref="`questionForm${i}`">
                      <v-textarea

                          v-if="item.options == null" v-model="answers[i]" :placeholder="item.placeHolder" outlined
                                  style="direction: rtl !important;"
                                  reverse></v-textarea>

                        <v-radio-group v-else v-model="answers[i]">

                          <v-radio
                              class="mt-2"
                              @click="goToNextQuestion(i)"
                              v-for="eachOptions in item.options"
                              :key="eachOptions.id"
                              :label="eachOptions.label"
                              :value="eachOptions.value"
                          ></v-radio>
                        </v-radio-group>

                    </v-form>
                    <div class="buttonSlier " @click="goToNextQuestion(i)">
                      بعدی
                    </div>
                  </div>
                </v-stepper-content>
              </v-stepper>
            </div>
          </div>
        </div>
        <div :key="3" v-else-if="state == 'profileData'">
          <div class="headerContext d-flex pt-8">

            <div>
              <img src="../../assets/logo/Logo.svg" alt="">
            </div>

          </div>

          <StaticPreSessionPersonalInfo ref="childForm"   :user="profile"/>

          <div class="mt-15">

            <div>
              <div class="buttonSlier" @click="saveProfile()">
                بعدی
              </div>
            </div>
          </div>
        </div>
        <div :key="4" v-else>
          <div class="headerContext d-flex pt-8 justify-center">
            <div>
              <img src="../../assets/logo/Logo.svg" alt="">
            </div>
          </div>
          <div class="introSlide mt-5">
            <img src="../../assets/static_presession/desktop-login-vector.c9aa7014fc15cfbf07a5.png" alt="">
            <div class="sliderContent">
              <div class="titleSlider">
                اطلاعات شما ثبت شد
              </div>
              <div class="contentSlider">
                سیستم ما در حال آماده سازی لیست دکتر های مناسب شماست تا از بین آن‌ها اقدام به انتخاب کنید.
              </div>
              <router-link :to="`/fa/panel/event/${event.id}`" v-if="event">
                <div class="buttonSlier " @click="e1++">
                  دکتر‌های پیشنهادی
                </div>
              </router-link>
            </div>
          </div>

        </div>

      </v-fade-transition>

    </div>

  </div>
</template>

<script>
 import {mapGetters} from "vuex";
 import StaticPreSessionPersonalInfo from "@/views/presession/staticPreSessionPersonalInfo.vue";


export default {

  components: {StaticPreSessionPersonalInfo},


   data() {
    return {
      e1: 1,
      questionStepper: 1,
      event: null,
      state: 'introSlider',
      notify: false,
      notifyColor: '',
      notifyText: '',
      answers: [],
      questions: [
        {
          id : 0,
          title : "نوع درمان",
          placeHolder : "",

          question : "نوع جلسات خود را مشخص کنید:",
          options : [
            {id : 0 , label : "فردی",value : "فردی"},
            {id : 1 , label : "زوجی",value : "زوجی"},
            {id : 2 , label : "کودک و نوجوان",value : "کودک و نوجوان"},
            {id : 3 , label : "روانپزشکی",value : "روانپزشکی"},
            {id : 4 , label : "پیش از ازدواج",value : "پیش از ازدواج"},
          ]
        },
        {
          id : 1,
          title: "حوزه درمان",
          placeHolder : "",
          question : "مسأله خود را شرح دهید:",
          options : null
        },
        {
          id : 2,
          title : "فانکشن",
          placeHolder : "با همسرم به مشکل برخوردم.",
          question : "این روزها تغییری در فعالیت‌های روزمره شما اتفاق افتاده است؟",
          options : null
        },
        {
          id : 3,
          title : "فانکشن",
          placeHolder : "اصلا اشتها ندارم",
          question : "اشتهای شما تغییری داشته است؟",
          options : null
        },
        {
          id : 4,
          title : "فانکشن",
          placeHolder : "شب ها دیر خوابم میبره.",
          question : "آیا در خوابیدن و به خواب رفتن مشکل دارید؟",
          options : null
        },
        {
          id : 5,
          title : "ریسک",
          placeHolder : "اره چند باری فکر کردم.",
          question : "تا حالا به خودکشی فکر کرده‌اید؟",
          options : null
        },
        {
          id : 6,
          title : "بیماری",
          placeHolder : "بله تیروئید و دیابت",
          question : "سابقه بیماری جسمی یا جراحی سنگین داشته‌اید؟",
          options : null
        },
        {
          id : 7,
          title : "سابقه درمان",
          placeHolder : "بله سال ها با مشاوران مختلف.",
          question : "آیاپیش از این تجربه مشاوره داشته‌اید؟",
          options : null
        },

        {
          id : 8,
          title : "انتظار درمان",
          placeHolder : "ترجیح می‌دم درمانگر زن و جوان باشه و تجربه مادر شدن داشته باشه محیطی امن و غیرقضاوتی ایجاد کنه.",
          question : "از تراپیست خود چه انتظاراتی دارید؟",
          options : null
        },

     {
          id : 9,
          title : "نحوه آشنایی",
       placeHolder : "توی گوگل سرچ کردم",
          question : "چطور با سیمیاروم آشنا شدید؟",
          options : null
        },
        {
          id : 10,
          title : "سایر",
          placeHolder : "من ازدواج با یک فرهنگ دیگر رو تجربه کردم و فرزندم ۲ زبانه است.",
          question : "چیزی هست که از شما پرسیده نشده؛ ولی لازم می‌دانید که برای معرفی تراپیست، حتما در نظر بگیریم؟",
          options : null
        },

      ],

    }
  },

  methods: {


    addProfileData () {


      const profileQuestions = [
        {
          id : 11,
          title : "اطلاعات هویتی",
          placeHolder : "",
          question : "نام و نام خانوادگی",
          options : null
        },
        {
          id : 12,
          title : "اطلاعات هویتی",
          placeHolder : "",
          question : "در چه کشوری زندگی میکنید ؟",
          options : null
        },
        {
          id : 13,
          title : "اطلاعات هویتی",
          placeHolder : "",
          question : "سن خود را وارد کنید.",
          options : null
        },
        // {
        //   id : 14,
        //   title : "اطلاعات هویتی",
        //   placeHolder : "",
        //   question : "جنسیت خود را انتخاب کنید.",
        //   options : [
        //     {id : 0 , label : "مرد",value : "مرد"},
        //     {id : 1 , label : "زن",value : "زن"},
        //     {id : 2 , label : "ترجیج میدم نگم.",value : "ترجیج میدم نگم."},
        //
        //   ]
        // }
        ]

      this.questions = [...this.questions,...profileQuestions]
      this.answers = [...this.answers , ...[this.profile.firstname+ "  "+ this.profile.lastname,this.profile.country.name, this.isJalali ? this.jalaliToGregorian(this.profile.birthDate) : this.profile.birthDate, this.profile.gender]]

    },


    saveProfile() {




      if (this.$refs.childForm.validateForm()) {

      let data = {
        birth_date: this.isJalali ? this.jalaliToGregorian(this.profile.birthDate) : this.profile.birthDate,
        name: this.profile.firstname,
        family: this.profile.lastname,
        gender: this.profile.gender,
        "country_id": this.profile.country_id,
      };

      this.$emitEvent('setLoading', true)
      this.requesting('auth', 'editProfile', '', data).then(() => {

      }).finally(() => {

        this.$emitEvent('setLoading', false)

        this.sendData()


      })
      this.state = "done"
    }},
    showNotify(text, color) {
      this.notify = true
      this.notifyText = text
      this.notifyColor = color
    },
    goToNextQuestion(i) {
      if (this.answers[i] != undefined && this.answers[i] != "") {
        if (this.questionStepper === this.questions.length) {
          this.state = "profileData"
        } else {
          this.questionStepper++; // Move to next step
        }
      } else {
        this.showNotify('به سوال پاسخ دهید', 'red')
      }
    },
    sendData() {


      let payload = []

      this.addProfileData()
      this.questions.forEach((item, i) => {
        payload.push({
          question: item.question,
          answers: this.answers[i]
        })
      })


      console.log({
        type: 'presession',
        sub_type: 'static_presession',
        version: 1,
        answers: payload,
      })

      this.$emitEvent('setLoading', true)
      this.requesting('user', 'processStaticPresession', {}, {
        type: 'presession',
        sub_type: 'static_presession',
        version: 1,
        answers: payload,
      }).then((resp) => {

        this.event = resp.data
        console.log(this.event,'vdsvdsvds')

      }).finally(() => {
        this.$emitEvent('setLoading', false)
      })
    },
    endIntroSlider() {
      this.state = 'question'
    },
  },
  computed: {
    ...mapGetters({
       'profile': 'getProfile',
     }),
    progress() {
      return (this.questionStepper / this.questions.length) * 100;
    }
  },
  beforeMount() {
    // Disable RTL for this page
    this.$vuetify.rtl = false;
  },
  beforeDestroy() {
    // Re-enable RTL when leaving the page
    this.$vuetify.rtl = true;
  },


}
</script>

<style scoped>
@media (min-width: 200px) and (max-width: 800px) {
  .stepperContext {
    width: 95% !important;
  }

  .introSlide img {
    width: 80% !important;
  }

  .titleQuestion {


    height: unset !important;
    margin-bottom: 25px ;

  }

}

.questionSlider {
  direction: rtl;
}

.titleQuestion {
  color: #3d5656;
  font-size: 13pt;
  height: 60px;
  font-family: bold !important;
}

.questionCounter {
  color: rgb(119 137 137/1);
}

.totalQuestionCount {
  color: rgb(61 86 86/1);
  font-family: bold !important;
}

.headerContext {
  direction: rtl !important;
  justify-content: space-between;
}

.buttonSlier {
  background: #1cba88;
  padding: 10px;
  font-family: bold !important;
  color: white;
  cursor: pointer;
  border-radius: 12px;
  margin-top: 50px;
  text-align: center;
}

.sliderContent {
  width: 90%;
  margin: 0px auto;
}

.stepperContext {
  width: 500px;
  margin: 0px auto;
}

.introSlide img {
  width: 100%;
}

.introSlide {
  text-align: center;
  direction: rtl;
}

.titleSlider {
  font-size: 15pt;
  font-family: bold !important;
  color: rgb(61 86 86/1);
  margin-bottom: 10px;
}

.contentSlider {
  font-size: 11pt;
  color: rgb(61 86 86/1);

}

</style>
