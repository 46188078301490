<template>
  <div   :class="$vuetify.breakpoint.mdAndDown ? 'mt-10' : 'mt-4'">
    <p class="titleSection">
      {{ $t('new_onboarding.stepTwo.title') }}
    </p>
    <div class="formArea">
      <v-form ref="personalForm">
        <v-row style="direction: rtl">
          <v-col cols="12" lg="6" >
            <v-text-field  reverse v-model="user.firstname" :error="false" :rules="requiredRules" outlined dense
                          style="border-radius: 12px"
                          :hint="$t('profileView.name_hint')"
                          persistent-hint
                          :label="$t('new_onboarding.stepTwo.name')"></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-text-field reverse v-model="user.lastname" :rules="requiredRules" outlined dense style="border-radius: 12px"
                          :label="$t('new_onboarding.stepTwo.family') "></v-text-field>
          </v-col>
          <v-col cols="12" lg="6" @click="showDialog=!showDialog;isJalali = true">
            <div class="ltrInput">
                 <v-text-field  reverse v-model="user.birthDate" :rules="requiredRules" outlined dense
                              style="border-radius: 12px"
                              :label="$t('new_onboarding.stepTwo.birthDay')"></v-text-field>
                <DatePicker @close="showDialog=false"
                            :dialog="showDialog"
                            @jalaliChange="changeIsJalali"
                            @input="showDialog = false"
                            v-model="user.birthDate"
                ></DatePicker>
             </div>
          </v-col>
          <v-col cols="12" lg="6">
             <div class="ltrInput">
               <v-autocomplete reverse dense placeholder="تایپ کنید..." v-model="user.country_id" :items="contires" item-text="label"
                               item-value="id"
                               :rules="requiredRules" outlined
                               style="border-radius: 12px"
                               :label="$t('new_onboarding.stepThree.country')"></v-autocomplete>
            </div>
          </v-col>
          <v-col cols="12" lg="6">

            <v-select reverse style="border-radius: 12px" item-text="title" item-value="value" v-model="user.gender" :items="genders"

                      :rules="requiredRules" dense outlined
                      :label="$t('profileView.sex')"></v-select>
          </v-col>

        </v-row>

      </v-form>
    </div>
  </div>
</template>

<script>

import AuthMixins from "@/mixins/AuthMixins";
import DatePicker from "@/components/Calendar/DatePicker.vue";

export default {
  components: {DatePicker},
   mixins: [AuthMixins],
  props: {
    user: Object,
    submiting: Boolean,
  },
  methods: {
    validateForm() {
      if (this.$refs.personalForm.validate()) {
        return true;
      } else {
        return false;
      }
    },
    changeIsJalali(val) {
      this.isJalali = val;
    },
    back() {
      this.$emit('prevHandler')
    }
  },
  data() {
    return {
      phone: null,
      showDialog: false,
      notifyEmail: true,
      isJalali: false,
      genders: [
        {
          title: this.$t('gender.male'),
          value: "MALE"
        },
        {
          title: this.$t('gender.female'),
          value: "FEMALE"
        },
        {
          title: this.$t('gender.other'),
          value: "OTHER"
        },
      ]
    }
  },
  mounted() {
    this.phone = this.user.cel
    this.$emit('changeIsJalali', this.isJalali)
    // setInterval(() => {
    //   console.log(this.submiting, 'hrere')
    // }, 500)
  },
  updated() {

  },
  watch: {
    phone() {
      this.user.cel = this.phone
    },
    submiting() {
      if (this.$refs.personalForm.validate()) {
        this.$emit('nextHandler', 1)
      }
    },
    isJalali(val) {
      this.$emit('changeIsJalali', val)
    }
  }
}
</script>

<style scoped>



@media (min-width: 200px) and (max-width: 600px) {
  .formArea {
    width: 100% !important;
    margin: 25px auto 0px auto !important;
  }

  .titleSection {
    font-size: 15px !important;
    margin-top: -30px !important;
  }

}

.titleSection {
  font-size: 16px;
}

.formArea {
  width: 90%;
  margin: 20px auto 0px auto;
}

</style>
